.buttons-container {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  margin-top: 8%;
}

.container {
  width: 100%;

  @media (min-width: 1650px) {
    width: 75%;
  }
}
