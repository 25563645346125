textarea {
  border-color: #e6e6e6;
  padding: 10px;
  border-radius: $primaryRadius;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

textarea::placeholder {
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #262626;
  opacity: 0.3;
}

.dropdown {
  height: 42px;
  max-width: 240px;
  border-radius: 10px !important;
  background: #ffffff;
  border: 1px solid #e6e6e6;
}

.k-picker-solid {
  background-color: white;
  border-radius: 10px !important;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  height: 42px;
}

* {
  accent-color: $accentColor;
}

.k-input .k-input-button {
  padding: 4px 4px;
  border: none;
  background: transparent;
}

.k-input {
  height: 40px;
  max-width: 326px;
  border-radius: $primaryRadius;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  font-size: 12px;
  line-height: normal;
  color: #262626;
}

.k-input-inner {
  resize: none;
}

.k-input::placeholder {
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #262626;
  opacity: 0.3;
}

.k-input-inner {
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  color: #262626;
}

.k-input-inner::placeholder {
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #262626;
  opacity: 0.3;
}

.checkbox-label {
  .k-checkbox-label {
    margin-left: 1rem;
    font-size: 14px;
  }
}

.k-input-solid.k-invalid,
.k-input-solid.ng-invalid.ng-touched,
.k-input-solid.ng-invalid.ng-dirty {
  border-color: #e6e6e6;
}

.k-multiselect {
  .k-chip-list {
    height: 50%;
    margin-top: auto;
  }
}

.checkbox {
  input[type="checkbox"] {
    margin-left: 0%;
    margin-right: 1%;
    height: 20px;
    width: 20px;
    background-color: white;
    font-weight: 300;
    color: black;
  }

  input[type="checkbox"]:checked {
    background-color: $accentColor;
  }
}

.numeric-input-padding {
  .k-input-inner {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.k-input-spinner {
  button {
    background-color: $primaryColour;
    color: $combo-icon-color;
  }
}

.search-button {
  .k-i-search {
    color: $buttonTextColor;
  }
}

.k-switch-on .k-switch-track {
  border-color: #ffc000;
  color: white;
  background-color: #ffc000;
  border-radius: 10px;
  width: 36px;
  height: 22px;
  outline: none;
}

.k-switch-off .k-switch-track {
  width: 36px;
  height: 22px;
  outline: none;
}

.k-switch-thumb {
  width: 17px !important;
  height: 17px !important;
}

.k-radio-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  // margin-left: 8px !important;
}

.radio-horizontal .k-radio-list {
  flex-flow: row nowrap !important;
  gap: 50px !important;
}

.radio-vertical .k-radio-list {
  flex-flow: column nowrap !important;
  flex-direction: column;
  gap: 15px !important;
}

.radio {
  // margin: 0.5rem;
  gap: 3%;

  .k-radio {
    position: absolute;
    opacity: 0;

    + .k-radio-label {
      display: flex;

      &:before {
        content: "";
        background: white;
        border-radius: 50% !important;
        border: 1px solid darken(black, 5%);
        // display: flex;
        width: 1.4em;
        height: 1.4em;
        position: relative;
        // top: -0.2em;
        margin-right: 1em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }

    &:checked {
      + .k-radio-label {
        &:before {
          background-color: $accentColor;
          box-shadow: inset 0 0 0 4px white;
        }
      }
    }

    &:focus {
      + .k-radio-label {
        &:before {
          outline: none;
        }
      }
    }

    &:disabled {
      + .k-radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px black;
          border-color: darken(black, 5%);
          background: darken(black, 5%);
        }
      }
    }

    + .k-radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

.k-switch-label-on,
.k-switch-label-off {
  display: none;
}

.k-checkbox:checked {
  border: none;
  background-color: $accentColor;
}

.date-form-to-wrapper {
  // width: 100%;
  // min-width: 370px;
  max-width: 330px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .divider {
    min-height: 30px;
    opacity: 0.5;
    border: 1px solid #000000;
  }

  .date-picker {
    width: 128px;
    height: 20px;
    font-weight: 400 !important;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    // min-width: 200px;
  }

  label {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
  }
}

.nominal-form-to-row {
  display: flex;
  align-items: center;

  input {
    font-size: 14px;
  }
  .show-reversed {
    margin-left: 43px;

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 5px;
    }
  }

  .refresh-btn-container {
    margin-left: auto;

    .refersh-btn {
      width: 113px;
      height: 50px;
      background: #f6db8a;
      border-radius: 10px;
    }

    .btn-label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 15px;
      text-align: center;
      color: #1f1801;
    }
  }
}

.filter-font {
  font-size: 14px;
}

.nominal-form-to-parent-row {
  display: flex;

  .labels-containers {
    margin-bottom: 7px;
    width: 330px;
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #262626;
  }

  .label-to {
    align-items: start;
    width: 50%;
  }
}

.k-switch-md {
  width: 36px;
  height: 19px;
}

.k-switch-md.k-switch-on .k-switch-thumb-wrap {
  left: calc(100% - 12px);
}

.no-borders {
  padding: 0px 10px 0px 10px;
  border-radius: 10px !important;
  background: #ffffff;
  border: 1px solid #e6e6e6;

  .k-input {
    border: none !important;
  }
}

.k-radio:checked,
.k-radio.k-checked {
  background-color: $primaryColour;
  border-color: $primaryColour;
}

.k-radio-label {
  margin-left: 7px !important;
}

.right-align {
  input {
    text-align: right;
  }
}

.right-align-full {
  input {
    text-align: right;
    padding: 0px;
  }
}

.form-date-picker {
  .k-datepicker {
    width: 208px;
    height: 32px;
  }
  .k-input-inner {
    padding-bottom: 12px;
  }
}
.note-date-picker {
  .k-datepicker {
    width: 260px;
    height: 38px;
  }
}

.doc-date-picker {
  .k-datepicker {
    width: 327px;
  }
}

.max-100 {
  .k-input {
    max-width: 100% !important;
  }
}

.k-label {
  font-size: 13px !important;
  font-weight: 600 !important;
  font-family: "Montserrat";
}

.check-date-picker{
  .k-datepicker{
    height: 32px;
  }
}

.margin-date {
  margin-top: 1.6px;
}

.border-less-inputs {
  .k-input {
    max-width: 100%;
    width: 100%;
    border: none;
  }
  input:focus {
    outline: none;
  }
}


.dropdown-width {
  width: 250px !important; 
  min-width: 100px !important;
}

.radio-group-gap{
  gap: 10px !important;
}
@media (max-width: 1689px) {
  .dropdown-width {
    width: 170px !important;
  }
  .radio-group-gap{
    gap: 0px !important;
  }
}

@media (max-width: 1400px) {
  .dropdown-width {
    width: 150px !important;
  }

}

@media (max-width: 1200px) {
  .dropdown-width {
    width: 120px !important;
  }
}

@media (max-width: 1024px) {
  .dropdown-width {
    width: 100px !important;
  }
}


