@import "../../styles/_theme.scss";

.move-button {
  background-color: $primaryButtonColour !important;
  color: $buttonTextColor !important;
  border: 0px solid $primaryButtonColour;
  width: 5rem;
  height: 40px;
  border-radius: $primaryRadius;
}

.move-button:hover {
  color: $buttonHoverText !important;
  background-color: $buttonHoverColor !important;
}

//This will make all nodes width to 100%
/*.treeviewItem {
  :global {
    .k-treeview-leaf,
    .k-treeview-leaf-text {
      width: 100% !important;
    }
  }
}*/

//Only group nodes to 100% width
.treeviewItem {
  :global {
    .k-treeview-leaf[data-type="group"],
    .k-treeview-leaf-text[data-type="group"] {
      width: 100% !important;
    }

    .k-treeview-leaf:has(li[data-type="group"]),
    .k-treeview-leaf-text:has(li[data-type="group"]) {
      width: 100% !important;
    }
  }
}

.groupAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  font-size: 14px;
  color: #ffffff;
  margin-right: 8px;
  background-color: $primaryColour;
}

.subgroupAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  font-size: 10px;
  color: #ffffff;
  margin-right: 8px;
  background-color: $thirdColour;
}
