@import "../../styles/theme";

.border-bottom {
  opacity: 0.1;
  border-bottom: 0.5px solid #000000;
}

.case-file {
  position: absolute;
  margin: auto;
  top: 0;
  right: 30%;
  left: 20%;
  width: 360px;
  height: 32px;
  background: url("../../assets/CaseFileIcons/header.svg") !important;
}

.case-file-opened {
  background-color: rgba(230, 228, 255, 0.1);
  border: 0px;
}

.case-number {
  border-radius: 5px;
  border: 0.5px solid #000;
  background: #e9e8fa;
  font-size: 14px;
  font-style: normal;
  width: 21px;
  height: 19px;
  font-weight: 600;
  line-height: 15px;
}

.tabs-header {
  //   border-radius: 25px 0px 0px 0px;
  //   background: #fff;
  position: absolute;
  margin: auto;
  width: calc(100% - 370px);
  overflow: hidden !important;
  min-height: 35px;
  top: 0;
}

.case-file-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color: #262626;
  line-height: 15px;
}

.list-item  {
  width: 100%;
  margin: auto;
}

.list-item:hover {
  background-color: $secondaryColour;
  color: black;
  border-radius: 5px;
}

.question-text {
  font-size: 13px;
  font-weight: 600;
  text-wrap: wrap;
  line-height: 15.85px;
}

.gray-text {
  color: #1f1801;
  opacity: 50%;
  line-height: 8px;
  font-size: 10px;
}

.number-text {
  font-size: 13px;
  font-weight: 600;
  line-height: 8px;
}

.box-styles {
  background-color: #f8f8f8;
  border-radius: 15px;
  height: 50px;
}

.items-list {
  border: 0.5px solid #e6e6e6;
  height: 40px;
}

.list {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  opacity: 0.8;
}
