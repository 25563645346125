@import "../../styles/theme";

.settings-height {
    height: calc(100vh - 120px);
}


.icon-back {
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  width: 35px;
  margin-right: 15px;
  height: 35px;
  cursor: pointer;
}

.menu-item {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  height: 40px;
  width: 60%;
  gap: 10%;
  min-width: 200px;
  padding-inline-start: 5%;
  cursor: pointer;
}

.menu-style {
  color: $settingOptionsColor;
  line-height: normal;
}

.icon-image {
  height: 20px;
  width: 20px;
}

.borders {
  border-top-left-radius: 25px;  
}

.active-circle {
  height: 55px;
  width: 55px;
  color: black;
  border-radius: 50%;
  display: inline-block;
}

.odd {
  background-color: #dafff3;
  color: #99b3aa;
  font-size: 20px;
}

.even {
  background-color: #cfe4ff;
  color: #99b3aa;
  font-size: 20px;
}

.button {
  min-width: 125px;
  height: 50px;
}

.hmrcButton {
  min-width: 125px;
  height: 44px;
}

.button-1 {
  font-size: x-large;
  width: 60px;
  min-height: 45px;
}

.hmrc-button {
  max-width: 200px !important;
  font-weight: normal;
  height: 50px;
}

.hmrc-button:hover {
  background-color: #ebebeb;
  opacity: 0.5;
}

.checkbox {
  input[type="checkbox"] {
    margin-left: 0%;
    margin-right: 1%;
    height: 20px;
    width: 20px;
    background-color: white;
    font-weight: 300;
    color: black;
  }

  input[type="checkbox"]:checked {
    background-color: $accentColor;
  }
  label {
    width: 100px;
  }
}

.marginLeft {
  input[type="checkbox"] {
    margin-left: 25%;
  }
}

.grid-width {
  width: 60%;
}

.file {
  p {
    line-height: 0px;
  }
}

.icon-design {
  color: black;
  opacity: 0.3;
  font-size: 20px;
  font-weight: 400;
}

.branches-grid {
  width: 70%;
}

.addressLines {
  color: #1f1801;
  opacity: 0.5;
  font-weight: 500;
  font-size: 12px;
  // line-height: 15px;
}

.bolder {
  font-style: normal;
  font-weight: 500 !important;
  line-height: 1.5em;
  color: #1f1801;
  opacity: 1 !important;
}

.pointer {
  cursor: pointer;
}

.weekDays {
  background-color: #f3f3f3;
  border-radius: 15px;
  height: 35px;
  width: 200px;

  input[type="checkbox"] {
    margin-left: 10px;
    height: 20px;
    width: 20px;
    background-color: $accentColor;
    font-weight: 300;
    border-radius: 50%;
    color: black;
  }

  input[type="checkbox"]:checked {
    background-color: $accentColor;
  }
}

.daysNames {
  font-weight: 500;
  width: 100px;
  margin-left: 2%;
}

.opacity {
  opacity: 0.5;
}

.right {
  margin-right: 15%;
}

.justify {
  justify-content: space-between;
}

.company {
  width: 100%;
}

.company-details {
  gap: 20%;

  @media (max-width: 1540px) {
    gap: 0;
    justify-content: space-between;
  }

  @media (max-width: 1250px) {
    gap: 0;
    flex-direction: column;
  }
}

.company-tabs {
  height: 65vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.button-margin {
  margin-right: 12%;
}

.input-address-component {
  ::placeholder {
    font-style: italic !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 15px !important;
    color: #262626 !important;
    opacity: 0.3 !important;
  }
}

.vat-checkbox {
  input[type="checkbox"] {
    border-radius: 50%;
    height: 18px;
    width: 18px;
  }

  margin-left: -30px;

  input[type="checkbox"]:checked {
    background-color: #ffc000;
  }
}

.vat-company-data {
  background-color: #f2f2f2;
  border-radius: 6px !important;
  color: #262626;
  font-style: normal;
  font-size: 10px;
  width: max-content !important;
  min-width: 160px !important;

  p {
    line-height: 5px;
  }
}

.company-name {
  opacity: 0.6;
}

.integration-card {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
  width: 400px;
  height: 200px;
}

.integration-footer {
  border-radius: 0px 0px 10px 10px;
  background: #f5f5f5;
  height: 50px;

  span {
    line-height: 0px;
  }
}

.connection-badge {
  background-color: #f1f1f1;
  border-radius: 30px;
  height: 25px;
  margin-left: 5%;
  width: 150px;

  p {
    color: #1f1801;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
  }
}

.badge {
  max-height: 25px;
}

.clio-text {
  p {
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    width: 80%;
  }
}

.blue-text {
  color: #162eff;
  cursor: pointer;
}

.label {
  width: 200px;
}

.connected-image {
  height: 12px;
  width: 12px;
}

.setup-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.setup-div {
  min-width: max-content;
}

.setup-container {
  width: 70%;
  margin: auto;
}

.gray-text {
  color: #adadad;
}

.edit-icon {
  cursor: pointer;
  color: $accentColor;
}

.image-container {
  width: 100px;
  height: 100px;
  border: 3px solid #707070;
  z-index: 1;
  border-radius: 10px;
}

.image-icon-contaniner {
  border-radius: 50%;
  background-color: #7d7ab4;
  width: 40px;
  height: 40px;
  z-index: 100;
}

.buttons-container {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
}

.work-type-field-style {
 width: 280px;
 height: 32px;
}