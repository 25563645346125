@import "../../styles/theme";

.grid-container {
  border-radius: 10px;
  border: 0.5px solid #bdbdbd;
  background: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  margin-left: auto;
}

.right-border {
  border-right: 0.5px solid #e6e6e6;
  min-width: 100px;
  text-align: center;
  background-color: #f8f8f8;
}

.numeric-right-border {
  border-right: 0.5px solid #e6e6e6;
}

.bg-Color {
  width: 150px;
}

.datePicker-bill {
  min-width: 156px;
  height: 40px;
  border-radius: 10px;
  width: 100% !important;
}

.custom-button {
  border: 0.94px solid rgba(220, 218, 255, 1) !important;
  background-color: rgba(248, 248, 248, 1) !important;
}

.custom-button:hover {
  border: 0.94px solid rgba(220, 218, 255, 1) !important;
  background-color: rgba(248, 248, 248, 1) !important;
  color: black !important;
}

.font-small {
  font-size: 10px;
  font-weight: 500;
  color: rgba(112, 112, 112, 1);
  line-height: 8px;
}

.font-amount {
  font-size: 18px;
  font-weight: 600;
}

.numeric-container {
  border-radius: 5px;
  width: max-content;
  border: 0.5px solid #e6e6e6;
}
