.batch-posting-grid {
  max-height: calc(100vh - 600px);
  overflow: auto;
}

@media (min-width: 1135px) {
  .batch-posting-grid {
    max-height: calc(100vh - 480px);
  }
}
