@import "../../styles/theme";



.view-tittle {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.active-tab {
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  margin-right: 21px;
  border-bottom: 2px solid #ffc000;
}

.active-tab:hover {
  cursor: default;
}

.tab {
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  opacity: 0.5;
  margin-right: 21px;
}

.tab:hover {
  cursor: pointer;
}

.col-1-container {
  background: #ffffff;
  box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  overflow: auto;
  // height: 80vh;
}

.section-title {
  font-weight: 700;
  font-size: 14px;
  line-height: normal;
}

.sec-border {
  margin-top: 9px;
  margin-bottom: 30px;
  opacity: 0.2;
  border-bottom: 0.5px solid #000000;
}

.marginTop {
  margin-top: -25px;
}

.grid-top {
  margin-top: -5px;
}

.col-2-sec1 {
  background: linear-gradient(
    360deg,
    #ffffff 0%,
    rgba(204, 203, 203, 0.12) 100%
  );
}

.details-row-padding {
  padding-left: 17px;
}

.contact-list-wrapper {
  max-height: calc(100vh - 650px);
  min-height: calc(100vh - 650px);
  // padding-top: 20px;
  overflow: auto;
  border-radius: 10px;
  // background-color: rgba(204, 203, 203, 0.12);
}

.del-container {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: none;
  // background-color: white;
}

.circleActive {
  // width: max-content;

  box-sizing: border-box;
  min-width: 90px;
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  background: #f1f0ff;
  // box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
}

.circle {
  // width: auto;

  box-sizing: border-box;
  min-width: 90px;
  padding-left: 10px;
  padding-right: 10px;
  height: 35px;
  // background: #f1f0ee;
  border-radius: 5px;
}

.matterform-tab-title {
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #707070;
  line-height: normal;
}

.matterform-tab-active-title {
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  line-height: normal;
}

.add-clients {
  p {
    line-height: 0px;
  }
}

.pdf-icon {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: white;

  :hover {
    cursor: pointer;
  }
}

.form-icon {
  :hover {
    cursor: pointer;
  }
}

.matter-form-tab-div {
  height: calc(80vh - 369px) !important;
  // overflow: auto !important
}

.matter-form-completion-div {
  height: calc(80vh - 369px) !important;
  // overflow: auto !important
}

@media screen and (max-width: 1200px) {
  .matter-form-tab-div {
    height: calc(80vh - 333px) !important;
    // overflow: auto !important
  }

  .matter-form-completion-div {
    height: calc(80vh - 394px) !important;
    // overflow: auto !important
  }
}

@media screen and (max-width: 992px) {
  .matter-form-tab-div {
    height: calc(80vh - 540px) !important;
    overflow-x: scroll;
  }

  .matter-form-completion-div {
    height: calc(80vh - 394px) !important;
    // overflow: auto !important
  }
}

.feeearner-multi-select {
  width: 80%;
  max-width: 100%;
  height: 44px;
  overflow: auto;
}

.font-text-size {
  font-size: 13px;
}

.min-w {
  min-width: 150px !important;
  // text-align: left !important;
}

.value-padding {
  padding-left: 28px;
}

.section-title {
  font-weight: 700;
  font-size: 14px;
  line-height: normal;
}

.popup-content {
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: #ffffff;
  border-radius: 15px;
  width: 185px;
}

.menu-hover:hover {
  background-color:#d9d9d9
}

.list-item:hover{
  background-color: $secondaryColour;
  color : black;
  width: 100%;
  border-radius: 5px;
}