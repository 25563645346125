@import "../../styles/theme";

.dashboard-card {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
}

.combo-label-icon {
  width: 26px;
  height: 26px;
  fill: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.1));
}

.combo-label-font {
  color: $primaryTextColor;
  font-size: 15px;
  line-height: normal;
  text-wrap: nowrap;
}

.matter-label-text {
  color: #1f1801;
  font-weight: 700;
  line-height: normal;
}

.small-text {
  font-size: 11px;
  font-style: normal;
  line-height: normal;
}

.matter-item {
  border-bottom: 1px solid #f3f3f3;
  height: 50px;
  background-color: $headingColour;
  border-radius: 5px;
}

.param-1 {
  width: 60px;
  height: 3px;
  background-color: $secondaryColour;
}

.param-2 {
  width: 60px;
  height: 3px;
  background-color: #707070;
}

.chart-legend {
  line-height: 12px;
  font-size: 12px;
}

.date-range-picker {
  //   border-start-end-radius: 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  height: 40px;
}

.date-range-picker2 {
  border-radius: 0px;
  height: 40px;
  margin-left: 3px;
  margin-right: 3px;
}

.iconContainer {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.clinet-dialog-top-div {
  border-radius: 0px 0px 25px 25px;
  .k-animation-container.k-animation-container-relative {
    width: 100% !important;
  }
}
