.gridheight {
  max-height: calc(100vh - 240px);
}

.section-title {
  font-weight: 700;
  font-size: 14px;
  line-height: normal;
}

.notes-text {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #707070;
}

.doc-upload-text {
  margin-right: 10px;
  color: #1f1801;
  text-align: center;
  font-family: Montserrat;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.file-upload-icon {
  border-radius: 10px;
  background: #262626;
  width: 36px;
  height: 35px;
}
