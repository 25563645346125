.emailViewerCard {
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: #ffffff;
  height: 100% !important;
  display: flex;
  flex-direction: column;
}

.emailViewerHeader {
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: #f1f3f5;
  position: sticky;
  top: 0;
  z-index: 10;
  border-bottom: 1px solid #e0e0e0;
}

.emailSubject {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  font-size: 1.5rem;
  color: #ffffff;
  margin-right: 15px;
  background-color: #007bff;
}

.emailViewerMeta {
  strong {
    font-size: 12px;
    color: #333;
  }
}

.emailViewerDate {
  text-align: right;
  color: #6c757d;
}

.emailViewerBody {
  padding: 1.5rem;
  background-color: #fff;
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
  flex-grow: 1;
  overflow-y: auto;
}

.emailViewerAttachments {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0.5rem;
  background-color: #f1f3f5;
  border-top: 1px solid #e0e0e0;
  position: sticky;
  top: 60px; // Adjust this based on the height of your header
  z-index: 9;
  overflow-x: auto;
}

.attachmentItem {
  display: flex;
  align-items: center;
  width: 200px; // Fixed width for consistency
  padding: 5px 10px;
  background-color: #e9ecef;
  border-radius: 5px;
  color: #007bff;
  text-decoration: none;
  white-space: nowrap; // Prevent text wrapping
  overflow: hidden; // Hide overflow
  text-overflow: ellipsis; // Add ellipsis for overflowed text
}

.attachmentItem img {
  width: 20px;
  margin-right: 5px;
}

.attachmentItem a {
  text-decoration: none;
  color: #007bff;
}

.attachmentItem a:hover {
  text-decoration: underline;
}
