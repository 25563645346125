.time_recorded_gridheight {
  max-height: calc(100vh - 260px);
}

.form {
  background: #fbfbfb;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  height: 100vh;
  width: 70vw;
  overflow: auto;
  margin: auto;
}

.popup-content {
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: #ffffff;
  border-radius: 15px;
}
