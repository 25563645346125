@import "../../styles/theme";

.company-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  padding-left: 24px;
  padding-top: 24px;
}

.border-bottom {
  opacity: 0.1;
  border-bottom: 0.5px solid #000000;
}

.popup-content {
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 280px;
  background: #ffffff;
  border-radius: 15px;
  margin-right: 30px;
}

.menu-popup {
  width: 230px;
  margin-right: 0px;
  margin-left: 30px;
}

.profile-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.profile-email {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  opacity: 0.4;
}

.job-title {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: $primaryColour;
  margin-top: 3px;
}

.profile-image {
  margin-left: 19px;
  margin-right: 17px;
  width: 40px;
  height: 40px;
  flex-basis: auto;

  img {
    width: auto;
    height: auto;
  }
}

.list {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  opacity: 0.8;
  // margin-left: 17px;
}

.popup-conatiner {
  position: absolute !important;
  right: 200px !important;
  top: 100px;
}

.notification-badge {
  position: absolute;
  inset: 10px 7px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid white;
  background: #ff0d0d;
}

.notification-popup {
  position: absolute !important;
  right: 150px !important;
  top: 120px;
}

.notification-content {
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 30%;
  min-width: 350px;
  background: #ffffff;
  border-radius: 15px;
}

.filled-circle {
  color: $accentColor;
}

.notificationText {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  opacity: 0.8;
}

.not-container {
  max-height: 15vw;
  overflow: scroll;
}

.dateText {
  font-size: 12px;
}

.unSeen-background {
  background-color: #f6f6f6;
}

.user-name {
  color: $primaryTextColor;
  font-size: 12px;
}

.gray-text {
  color: #707070;
  line-height: 15px;
  font-size: 11px;
}

.image-container {
  width: 45px;
  height: 45px;
  border-radius: 10px;
  border: 3px solid #707070;
  overflow: hidden;
}

.notifications-border {
  border-radius: 7px;
  border: 2px solid #f0f1f2;
}

.menu-hover:hover {
  background-color:#f0f1f2;
  border-radius: 50%;
}

.list-item{
  width: 90%;
  margin: auto;
}

.list-item:hover{
  background-color: $secondaryColour;
  color : black;
  border-radius: 5px;
}
