.container {
  display: flex;
  padding: 5px;
}

.documentViewer {
  flex: 3;
}

.documentList {
  flex: 1;
  padding: 0px;
  overflow-y: auto;
}

.documentItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 15px;
  background-color: #ffffff;
  border-radius: 5px;
  border: 2px solid #f2f2f6;
  font-size: 12px;
  word-wrap: break-word;
  margin-bottom: 10px;
  margin-right: 25px;
}

.documentItem:hover {
  background-color: #f7f7f8;
}

.activeDocumentItem {
  background-color: #f7f7f8;
}

.documentName {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.removeIcon {
  cursor: pointer;
  margin-left: 15px;
  width: 18px;
  height: 18px;
}
