.reportBackground {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    margin: auto;
}

.height {
    height: 81vh;
    //min-height: max-content;
    overflow: auto;
}
.filter{
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 5px;
    background-color: red;
}
// .report-small-screen {
//     @media (min-width : 1400px) {
//         display: none;
//     }
// }

.report-large-screen {
    @media (max-width : 1400px) {
        display: none;
    }
}

.heading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;

}

.filter-height {
    height: 81vh;
    overflow: auto;
}


.filterBackground  {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    margin: auto;
}

@media (min-width: 1200px) {
    .maxWidthXL {
        max-width: 300px !important;
    }
}
 
@media (max-width: 1200px) {
    .maxHeightXL {
        max-height: 200px !important;
    }
    .filter{
        flex-direction: row !important;
    }
    .filter-height {
        max-height: 15vh;
    }
   
    .filterBackground{
        box-shadow: none;
    }
}

