@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto-Regular.ttf") format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap");

body {
    font-family: "Montserrat";
}


.label {
    font-size: 13px;
    font-weight: 600;
    line-height: normal;
    font-style: normal;
}

.fs-xl {
    //For 1400 width, font-size: 30px
    font-size: 1rem;

    @media (min-width: 768px) {
        font-size: calc(1vw + 0.5rem) !important;
    }

    @media (min-width: 992px) {
        font-size: calc(1vw + 0.6rem);
    }

    @media (min-width: 1200px) {
        font-size: calc(1vw + 0.8rem);
    }
}

.fs-lg {
    font-size: 0.7rem;

    @media (min-width: 768px) {
        font-size: calc(0.4vw + 0.4rem);
    }

    @media (min-width: 992px) {
        font-size: calc(0.5vw + 0.6rem);
    }

    @media (min-width: 1200px) {
        font-size: calc(0.5vw + 0.7rem);
    }
}


.fs-md {

    //For 1900 width = (9.5 + 6) = 15.5
    //For 1600 width = (8 + 6) = 14
    //For 1400 width = (7 + 6) = 13
    //For 1200 width = (6 + 6) = 12

    font-size: clamp(12px, calc(0.5vw + 0.375rem), 14px);

    td {
        font-size: clamp(12px, calc(0.5vw + 0.375rem), 14px);
    }
}


.fs-sm {
    font-size: 0.7rem;

    @media (min-width: 768px) {
        font-size: calc(0.5vw + 0.3rem);
    }

    @media (min-width: 1400px) {
        font-size: 0.75rem; // 12px
    }
}

.fs-sm-imp {
    font-size: 0.7rem !important;

    @media (min-width: 768px) {
        font-size: calc(0.5vw + 0.3rem) !important;
    }

    @media (min-width: 1400px) {
        font-size: 0.75rem !important; // 12px
    }
}

.fs-xsm {
    font-size: 0.68rem; // 10.88px
}

.fw-semibold {
    font-weight: 600;
}


.text-prewrap {
    white-space: pre-wrap;
}