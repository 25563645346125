.contact_gridheight {
  max-height: calc(100vh - 230px);
}

.del-container {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: none;
  // background-color: white;
}

.type-combobox {
  width: 113px !important;
  height: 33px !important;
  background: #f7f7f7 !important;
}

.contact-type-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.contact-form-tab-div {
  height: calc(95vh - 423px) !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.contact-form-company {
  height: calc(95vh - 421px) !important;
  overflow: auto !important;
  overflow-x: hidden !important;
}
@media screen and (max-width: 1399px) {
  .contact-form-tab-div {
    height: calc(95vh - 423px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
  .contact-form-company {
    height: calc(95vh - 421px) !important;
    overflow: auto !important;
    overflow-x: hidden !important;
  }
}
@media screen and (max-width: 1199px) {
  .contact-form-tab-div {
    height: calc(95vh - 540px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
  .contact-form-company {
    height: calc(95vh - 462px) !important;
    overflow: auto !important;
    overflow-x: hidden !important;
  }
}

@media screen and (max-width: 992px) {
  .contact-form-tab-div {
    height: calc(95vh - 556px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
  .contact-form-company {
    height: calc(95vh - 462px) !important;
    overflow: auto !important;
    overflow-x: hidden !important;
  }
}

@media screen and (max-width: 767px) {
  .contact-form-tab-div {
    height: calc(95vh - 618px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
  .contact-form-company {
    height: calc(95vh - 526px) !important;
    overflow: auto !important;
    overflow-x: hidden !important;
  }
}

.form-footer {
  background: #f4f4f4;
  border-radius: 0px 0px 25px 25px;
  display: flex;
  margin-top: 14px;
  justify-content: end;
  align-items: center;
}

.section-title {
  font-weight: 700;
  font-size: 14px;
  line-height: normal;
}
