
.bankGrid {
    max-height: calc(100vh - 480px);
}

@media (max-width: 1400px) {
    .bankGrid {
        max-height: calc(100vh - 600px);
    }
}
