.question-text {
  font-size: 14px;
  font-weight: 600;
  font-family: Montserrat;
}

.question-box {
  border: 1px solid #e6e6e6;
  height: 75px;
  overflow: hidden;
}

.checkboxes {
  font-family: "Montserrat";
  max-width: 100px;
  font-size: 13px;
  font-weight: 600;
}

.header-text {
  font-family: "Montserrat";
  font-size: 13px;
}

.detail-text {
  color: #707070;
}

.button-group {
  width: 160px;
  border: 1px solid #707070;
  padding-block: 5px;
  border-radius: 50px;
}
