

.tile-shadow {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}


.tile-heading {
    font-weight: 700;
    font-size: 14px;
    line-height: normal;
}
