.setting-btn-container {
  position: absolute;
  top: 10px;
  right: 5px;
}

.setting-btn-left {
  position: absolute;
  top: 10px;
  left: 5px;
}

.dark-background {
  background: #eaeaea;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub-text {
  color: red;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
