
.gridheight {
    max-height: calc(100vh - 240px);
}

.notes-tile {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
    height: 450px;
}