.container {
  padding-inline: 80px;
}

@media (max-width: 1400px) {
  .container {
    padding-inline: 50px;
  }
}

@media (max-width: 1250px) {
  .container {
    padding-inline: 25px;
  }
}

@media (min-width: 1600px) {
  .container {
    padding-inline: 20%;
  }
}

.error {
  border: 1px solid red !important;
}
