@import "../../styles/theme";

.dashboardContainer {
  height: calc(100vh - 80px);
  width: 100px;
}

.tiles {
  border-radius: 20px;
}

.statsTile {
  min-height: 150px;
  max-height: 150px;
}

.chartsTile {
  min-height: 300px;
  max-height: 300px;
}

.combo-label-font {
  color: $primaryTextColor;
  font-size: 15px;
  line-height: normal;
}

.iconContainer {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}
