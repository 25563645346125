@import "../../../styles/theme";

.tabItem {
    margin-bottom: 6px;
    box-sizing: border-box;
    min-width: 100px;
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
}

.selectedItem {
    background: $innerTabColour;
}


.unselectedItem {
    background-color: $headingColour;
}


.selectedItem-text {
    font-weight: 600;
    font-style: normal;
    font-size: 13px;
    line-height: normal;
}


.unselectedItem-text {
    font-weight: 500;
    font-style: normal;
    font-size: 13px;
    color: $secondaryTextColor;
    line-height: normal;
}
