@import "./theme";

.flex {
  display: flex;
}

h1 {
  font-weight: 500;
}

.width-100 {
  width: 100%;
}

.tableRow {
}

.font-14 {
  font-size: 14px;
}

.k-tabstrip-content.k-active,
.k-tabstrip > .k-content.k-active {
  // top: 40px;
  // overflow-y: scroll;
  height: 100% !important;
  overflow: hidden;

  // height: 88vh;
  // height: 100%;
  // overflow: auto !important;
  // padding: 16px 5px 40px 10px !important;
  @media (max-width: 1200px) {
    // overflow: scroll;
  }
}

.k-table-thead,
.k-table-header,
.k-table-group-sticky-header {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #f8f8f8 !important;
}

table thead tr:nth-child(1) {
  // border-bottom: 1px solid #0b1dc9 !important;
  // background-color: #f8f8f8 !important;
  padding-right: 0px;
}

table thead tr:nth-child(1) th {
  //text-align: center !important;
  // border-bottom: 1px solid #0b1dc9 !important;
}

table thead tr:nth-child(1) th span span.k-link {
  justify-content: left;
}

.k-grid-header {
  // padding-right: 0px;
}

// .k-virtual-content {
//   overflow-y: hidden;
// }
.k-tabstrip-items-wrapper .k-item:hover,
.k-tabstrip-items-wrapper .k-item.k-state-hover {
  color: #0b1dc9;
}

.link {
  color: #0b1dc9;
  cursor: pointer;
}

.k-selected {
  color: #0b1dc9 !important;
  background-color: #dee5f7 !important;
}

// .k-datepicker {
//   min-width: none;
// }

.k-grid table {
  border-collapse: collapse !important;
}

.footer-image-download-container {
  position: absolute !important;
  bottom: 4px !important;
  right: 60px !important;
  width: 40px;
  height: 40px;
}

.pr {
  position: "relative";
}

.footer-image-print-container {
  position: absolute;
  bottom: 4px;
  right: 10px;
  width: 40px;
  height: 40px;
}

.contact-page-margin {
  margin-top: 60px;
}

.targets {
  .k-grid-container .k-grid-content {
    overflow-y: scroll;
  }

  td {
    padding: 3px 12px;
  }

  .k-table-row.k-grouping-row {
    p {
      font-weight: 700;
    }
  }
}

.nominalLedger .k-grid-container .k-grid-content {
  overflow: hidden;
}

.xl-dialog {
  width: 1000px;
  height: 700px;
  background: #ffffff;
  border-radius: 25px;
}

.batchposting .k-dialog-titlebar {
  background-color: #253850;
}

.k-dialog-actions {
  color: black;
}

.k-dialog-content {
  overflow-x: hidden;
}

.k-grid td {
  border-width: 0 0 0px 0px;
  vertical-align: middle;
}
.nominalLedger thead tr:nth-child(1) th span span.k-link {
  justify-content: left;
  text-align: left;
  background: rgba(255, 249, 231, 0.5) !important;
  opacity: 0.5;
  border: 0.3px solid #bdbdbd;
  border-radius: 0px;
}

.settings {
  display: flex;
  gap: 10%;
  margin-left: 3vh;
  margin-top: 20px;
}

.settings-heading {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-top: 23px;
}

.settings h3 {
  width: max-content;
  padding-bottom: 4px;
  border-bottom: 1px solid gray;
}

.settings p {
  color: blue;
  cursor: pointer;
  padding-left: 20px;
}

.settings p:hover {
  color: black;
}

.company h2 {
  padding-top: 30px;
}

.company input {
  width: 300px;
}

.userstable .k-grid-header thead tr:nth-child(1) th span span.k-link {
  justify-content: left;
}

.cross {
  position: fixed;
  right: 80px;
  padding-top: 100px;
  cursor: pointer;
}

input.k-textbox {
  margin-top: 5px;
}

.Layout {
  background-color: white;
  padding: 20px 40px;
  height: calc(100vh - 110px);
  // overflow: scroll;
  width: 93%;

  @media (min-width: 1700px) {
    width: 95%;
  }

  @media (min-width: 2200px) {
    width: 98%;
  }
}

.Layout-1 {
  background-color: white;
  padding: 20px 40px;
  height: 100%;
  overflow: auto;
  width: 93%;

  @media (min-width: 1700px) {
    width: 95%;
  }

  @media (min-width: 2200px) {
    width: 98%;
  }
}

.sectionstyle {
  display: flex;
  gap: 7vw;
  line-height: 20px;
  align-items: center;
  margin-bottom: 10px;

  p {
    width: 150px;
  }

  input {
    width: 300px;
    margin-top: 0px;
  }

  .k-input {
    width: 300px;
  }
}

.sectionstyle-1 {
  display: flex;
  gap: 7vw;
  line-height: 20px;
  align-items: center;

  p {
    width: 150px;
  }
}

.text-align-right input {
  text-align: right;
}

.pagestyle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 10px;
}

.buttonstyle {
  float: right;
  padding-top: 15px;
  display: flex;
  gap: 10px;
}

.buttonstyle-5 {
  width: 80%;
  margin: auto;
  align-items: flex-end;
  background-color: gray;

  button {
    margin-left: 92%;
  }
}

.buttonstyle-3 {
  float: right;
  margin-top: -30px;
  display: flex;
  gap: 10px;
}

.dialogClass {
  margin-top: 20px;
  margin-bottom: 15px;
}

.cp {
  padding-right: 10px;
  width: 300px;
  font-weight: 600;
}

.k-picker-solid:hover {
  background-color: white;
}

.k-checkbox {
  border-color: #12000040 !important;
}

.k-checkbox + .k-label,
.k-checkbox-wrap + .k-label,
.k-checkbox + .k-checkbox-label,
.k-checkbox-wrap + .k-checkbox-label {
  margin-left: 1rem;
}

.k-checkbox:checked:focus,
.k-checkbox.k-checked.k-state-focus {
  box-shadow: none !important;
}

.k-menu:not(.k-context-menu) .k-item {
  color: #424242 !important;
}

.nominals-dropdown {
  display: flex;
  gap: 30px;
  padding: 40px 0 40px 0;
  align-items: center;
}

.nominals-dropdown select {
  border-radius: 5px;
  width: 250px;
  height: 40px;
  cursor: pointer;
  font-size: 15px;
  text-align: center;
}

.k-menu-horizontal .k-menu-expand-arrow {
  display: none !important;
}

.k-menu-horizontal .k-menu-link {
  margin-left: -7px;
}

.k-i-download {
  height: 30px;
}

// .k-item.k-menu-item {
//   background-color:
//     #F3F3F3
// }

.mt-7 {
  margin-top: 7px;
}

.mt-10 {
  margin-top: 10px;
}

.supplierDialog .k-dialog {
  width: 50%;
  overflow: hidden;
  margin: 40px;
}

.k-input {
  background-color: white;
}

.k-select {
  background-color: white;
}

.default-nominals {
  display: flex;
  gap: 0px 10vw;
  flex-wrap: wrap;
}

.posting-details-modal {
  display: flex;
  gap: 0px 10vw;
}

.k-menu:not(.k-context-menu) > .k-item:hover,
.k-menu:not(.k-context-menu) > .k-item.k-hover {
  color: #0b1dc9;
}

.k-pager-numbers .k-link.k-selected {
  color: #0b1dc9;
}

.k-pager-numbers .k-link:hover,
.k-pager-numbers .k-link.k-hover {
  background-color: #edf1fa !important;
}

.datepicker {
  min-width: 255px !important;
  height: 44px;
}

.labelRight {
  margin-right: 10px;
}

.popUpDropDown {
  z-index: 999;
  font-size: 14px !important;
  position: absolute;
  background-color: white;
  font-weight: 500;
  padding: 10px;
  min-width: 180px;
  margin-top: 4px;
}

.k-grid-table {
  width: 100% !important;
}

table {
  width: 100% !important;
}

.columnPadding {
  padding-top: 5px;
  padding-bottom: 5px;
}

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  column-gap: 10px;
  width: 100%;
}

.validator {
  display: flex;
  flex-direction: column;

  i {
    color: red;
  }
}

.k-stepper .k-step-done .k-step-indicator {
  color: #0b1dc9 !important;
  background-color: #dee5f7 !important;
  border: none !important;
}

.k-stepper .k-step-current .k-step-indicator {
  color: #0b1dc9 !important;
  background-color: #dee5f7 !important;
  border: none;
}

.k-stepper .k-step-indicator::after {
  border: none;
}

.grid-layout-container .datepicker {
  width: 250px !important;
}

.centerCard {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.border-top {
  padding: 10px 240px 0px 240px;
  border-top: 1px solid #d4d1d1ee;
  margin-top: 15px;
}

.font-weight-400 {
  font-weight: 400;
}

.wizard_menu {
  margin: 100px auto;
  width: 50%;
  border: 2px solid gray;
  padding: 30px 0px 30px 10px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_strip {
  display: flex;
  width: 100%;
  padding: 0px;
  justify-content: space-between;
}

.doc_popup {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
  width: 450px;
  height: 320px;
  flex-shrink: 0;
}

.doc_header {
  background-color: rgba(192, 186, 186, 0.5);
  padding: 0.5px;
  color: black;

  p {
    line-height: 0px;
    margin-left: 10px;
  }
}

.doc_btns {
  margin: 0px auto;
  bottom: 10px;
}

.fileName {
  color: black;
  font-weight: 600;
  line-height: 0px;
  margin-left: 20px;
}

.fileDetails {
  color: gray;
  margin-left: 20px;
  line-height: 0px;
}

.file {
  display: flex;
  line-height: 20px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid #d4d1d1ee;
  border-bottom: 2px solid #d4d1d1ee;
}

.file:hover {
  cursor: pointer;
}

.popup-items {
  display: flex;
  height: 95%;
  flex-direction: column;
  justify-content: space-between;

  .k-selected {
    background-color: blue !important;
    color: white !important;
  }
}

button {
  cursor: pointer;
}

input[type="file"] {
  display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.usersCard {
  width: 370px;
  height: 180px;
  border-radius: 10px;
  padding: 20px;
  background: #f7f7f8;
  border: 1px solid #dde5eb;
  margin-bottom: 20px;
  box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.12);
}

.usersEmail {
  margin-top: 20px;
  color: blue;
}

.usersEmail-1 {
  margin-top: 20px;
  color: black;
  display: flex;
  gap: 30px;

  span {
    width: 50px;
  }
}

.usersName {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #cbcbcb;
  padding-bottom: 10px;
}

.viewTopBar {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  width: 100%;

  p {
    line-height: 10px;
    font-size: 1.2em;
    font-weight: 600;
  }
}

.container {
  .heading {
    border-bottom: 2px solid black;
    background-color: lightblue;
    padding: 3px;

    p {
      font-weight: 600;
      line-height: 0px;
    }
  }
}

.heading {
  border-bottom: 2px solid black;
  // text-align: center;
  background-color: lightblue;
  padding: 3px;

  p {
    font-weight: 600;
    line-height: 0px;
  }
}

.pageStructure {
  display: flex;
  gap: 1%;
}

.matterDetails {
  display: flex;
  gap: 10%;
  margin-left: 10px;

  p {
    font-weight: 550;
    line-height: 20px;
  }

  .title {
    width: 80px;
  }
}

.viewOptions {
  display: flex;
  gap: 0px;
}

.viewOptionsMenu {
  margin: 15px;
  background-color: lightblue;
  width: max-content;
  padding: 05px 50px 02px 50px;
  cursor: pointer;
}

.viewOptionsMenuActive {
  border: 3px solid blue;
  margin: 15px;
  background-color: lightblue;
  width: max-content;
  padding: 05px 50px 05px 50px;
  font-weight: 600;
  cursor: pointer;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  color: black;
  background: lightgray;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.counters {
  padding: 6px;
  border: 2px solid black;
  border-radius: 50%;
}

.gray-color {
  color: gray;
  line-height: 5px;
}

.balances {
  p {
    line-height: 15px;
    margin-left: 20px;
  }
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.balances-1 {
  display: flex;
  flex-direction: column;
  line-height: 0px;

  p {
    line-height: 0px;
    float: right;
  }
}

.no-file-uploaded {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  color: gray;
  margin-top: 50px;
  gap: 8px;
}

.contact-link-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1%;
  background-color: whitesmoke;
  width: 68%;
}

.contactlink-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.contact-link-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1%;
  background-color: whitesmoke;
  width: 68%;
}

.contactlink-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.user-edit-section {
  display: flex;
  justify-content: space-between;

  input {
    width: 300px;
  }
}

.width-250 {
  input {
    width: 250px;
  }
}

.k-grouping-header {
  display: none;
}

.k-table-row.k-grouping-row {
  input {
    display: none;
  }

  .right-align-full {
    display: none;
  }

  .left-align-full {
    display: none;
  }

  .k-icon.k-i-caret-alt-down {
    display: none;
  }

  p {
    font-weight: bolder;
  }
}

.k-icon.k-i-x.k-button-icon {
  color: black;
}

[role="tabpanel"] {
  height: 100%;
}

.k-widget k-grid {
  overflow: auto;
}

.k-tabstrip-items .k-link {
  padding: 0;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: #000000;
}

.k-clear-value {
  margin-right: 0.5rem;
}

.k-input-solid:focus-within,
.k-input-solid.k-focus {
  box-shadow: none !important;
}

.move-up {
  margin-top: -3rem !important;
}

.k-appbar-light {
  color: black;
  background-color: white;
  padding-top: 26px;
  height: 90px;
}

.pa {
  position: absolute;
}

.bt-value {
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  opacity: 0.6;
}

.bt-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
}

.mb {
  border-bottom: 0.3px solid #bdbdbd;
  padding-bottom: 2px;
}

.hover {
  cursor: pointer;
}

.hover-shadow:hover {
  box-shadow: 0px 0px 4px 0px $secondaryTextColor;
  cursor: pointer;
}
