h1,
h2,
h3 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
    cursor: default;
}

.h-80 {
  height: 80% !important;
}

.justify-space-between{
    justify-content: space-between !important;
}
