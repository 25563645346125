@import "../../../styles/theme";


.tabItem {
    margin-bottom: 6px;
    box-sizing: border-box;
    width: 375px;
    min-width: 300px;
    height: 48px;
    line-height: 15px;
    font-size: 12px;
    font-style: normal;
    background: url("../../../assets/CaseFileIcons/tabs/firsttab.svg") !important;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
   
}

.selectedItem {
    color: $primaryTextColor;
    font-weight: 600;
    line-height: 15px;
    border-bottom: 4px solid #ffc000 !important;    
}


.unselectedItem {
    color: $secondaryTextColor;
    font-weight: 600;
    line-height: 15px;
    border-bottom: 4px solid transparent;
}



.tabs-container {
    border-radius: 0px;
    height: 100%;
}

.tabs-remove-icon-wrapper {
    background: $crossIconColor;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-right: 15px;
}




.tab-fonts22 {
    color: #707070;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px; /* 93.75% */
}

.first-tab {
    width: 375px;
    height: 48px;
    background: url("../../../assets/CaseFileIcons/tabs/firsttab.svg") !important;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    padding-right: 15px;
    border-bottom: 4px solid transparent;
}

.first-case-active {
    border-bottom: 4px solid #ffc000 !important;
}

.second-tab {
    margin-left: -20px;
    width: 375px;
    height: 48px;
    background: url("../../../assets/CaseFileIcons/tabs/secondtab.svg") !important;
    font-size: 13px;
    font-style: normal;
    border-bottom: 4px solid transparent;
    font-weight: 600;
    line-height: 15px;
}


.case-file-icon-wrapper {
    background: #f0f0f0;
    margin-right: 25px !important;
}

.case-file-selected {
    color: #262626 !important;
}

.case-file-unselected {
    color: #707070 !important;
}
