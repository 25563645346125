.icon-background {
  width: 24px;
  height: 24px;
  border-radius: 20px;
  background: rgba(244, 243, 255, 1);
}

.vertical-border {
  border: 1px solid gray;
  height: 25px;
}

.file-toolbar {
  height: 50px;
  background-color: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.06);
}

.spliter-class {
  background-color: #f0f0f0 !important;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  height: 30px;
  font-size: 7.5px;
}

.cardContainer {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
}

.versionTitle {
  font-weight: 800;
  font-size: 12px;
  margin-bottom: 8px;
}

.dateContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.dateText {
  font-size: 12px;
  color: gray;
}

.currentLabel {
  display: flex;
  align-items: center;
  background-color: white;
  justify-content: center;
  border: 0.5px solid lightgray; /* Light border */
  border-radius: 4px;
  height: 24px;
}

.currentIcon {
  font-size: 15px;
  color: #f57c00; /* Match the icon color */
}

.currentText {
  font-size: 0.7rem; /* Small text size */
}

.vertical-border {
  height: 35px;
  width: 1px;
  background: black;
  opacity: 0.2;
}
