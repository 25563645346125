@import "../../styles/theme";

.tabs-container {
  border-radius: 0px;
  height: 100%;
}

.tabs-remove-icon-wrapper {
  background: $crossIconColor;
  width: 20px;
  height: 20px;
  border-radius: 20px;
}

.r-icon {
  width: 6px;
  height: 6px;
  color: #ff0d0d !important;
}

.r-icon-unselected {
  width: 6px;
  height: 6px;
  color: #1f1801 !important;
}

.tab-fonts {
  color: #707070;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px; /* 93.75% */
}


.case-file-icon-wrapper {
  background: #f0f0f0;
}

.case-file-selected {
  color: #262626 !important;
}

.case-file-unselected {
  color: #707070 !important;
}

