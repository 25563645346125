@import "../theme";

.headerTab {
  .k-tabstrip-items-wrapper {
    border: none !important;
  }

  .k-item.k-first.k-active {
    p {
      font-weight: 500;
    }
  }

  .k-tabstrip-items {
    gap: 3%;
  }

  .k-tabstrip-items .k-item {
    border: none;
  }

  .k-tabstrip-top > .k-tabstrip-items-wrapper .k-item {
    margin-bottom: 0px;
  }

  .k-content.k-active {
    border: none;
    height: 100% !important;
  }

  .k-pager-numbers .k-link {
    color: #424242 !important;
  }

  .k-drawer-item:hover,
  .k-drawer-item.k-state-hover {
    color: #424242;
    background-color: #dee5f7;
  }

  .k-animation-container {
    width: 100%;
  }

  //Display only active tab
  .k-tabstrip-items.k-reset {
    .k-item {
      display: none;
    }

    .k-item.k-active {
      display: inline;
    }
  }

  .k-content {
    padding: 0;
    border: none;
  }
}

.navigationTab {
  position: relative;
  top: 35px;
  font-size: 1rem;
  //Display all tabs
  .k-tabstrip-items-wrapper.k-hstack {
    margin-left: 20px !important;
  }

  .k-tabstrip-items {
    border-bottom: 1px solid #e1e1e1;
  }

  .k-tabstrip-items.k-reset {
    .k-item {
      display: inline !important;
      border-bottom: 3px solid $secondaryColour;
    }

    .k-item.k-active {
      display: inline !important;
      border-bottom: 4px solid $accentColor;
    }

    .k-item.k-last.k-active {
      display: inline;
    }
  }

  .k-content {
    padding: 0;
    border: none;
  }
}

.pagetab {

    .k-tabstrip-items {
        border-bottom: 1px solid #e6e6e6;
        gap: 20px !important;

         @media (max-width: 1400px) {
            gap: 10px !important;
        }
    }

    .k-tabstrip-items-wrapper.k-hstack {
        background: none;
        margin-right: 20px;
        box-shadow: none;
    }

    .k-tabstrip-items.k-reset {
        .k-item {
            min-width: 100px;
            font-weight: 500;
            font-size: 13px;
            color: #707070;
            display: inline !important;
            background-color: white;
            border-bottom: 3px solid #707070;
        }

        .k-item.k-active {
            font-weight: 600;
            font-size: 13px;
            color: #262626;
            display: inline !important;
            background-color: white;
            border-bottom: 3px solid #162eff;
        }

        .k-item.k-last.k-active {
            display: inline;
        }
    }

    .k-content {
        padding: 0;
        border: none;
    }

    
}

.k-tabstrip-content {
    padding: 0px;
    border-style: none;
}

.pagetab-client {
    .k-animation-container {
        width: 100%;
    }
}

.case-files-tab {
    .k-tabstrip-items-wrapper.k-hstack {
        margin-left: 0px !important;
        width: calc(100% - 200px);
    }

    .k-tabstrip-prev.k-disabled {
        display: none;
    }

    .k-tabstrip-next.k-disabled {
        display: none;
    }
}
