@import "../../styles/_theme.scss";


.primary-pill {
    background-color: $secondaryButtonColour;
    color: $primaryTextColor !important;
    font-size: 11px !important;
    line-height: normal;
    font-weight: 600;
}

.secondary-pill {
    background-color: #dafff3;
    color: $primaryTextColor !important;
    font-size: 11px !important;
    line-height: normal;
    font-weight: 600;
}

.image-pill {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondaryTextColor;
    font-size: 12px;
    line-height: normal;
    font-weight: 600;
    border: 1px solid $secondaryButtonColour;
    cursor: pointer;
    padding: 0.65em 0.35em;
    text-align: center;
    border-radius: 0.375rem;
    white-space: nowrap;
    vertical-align: baseline;
}

.badge-container {
    width: 18px ;
    height: 18px ;
    display: flex;
    align-items: center;
    justify-content: center; 
    border-radius: 50%;
    background-color: #707070;
    margin-right: 6% ;
}

.selected{
    background-color: #162EFF;
    width: 18px ;
    height: 18px ;
    display: flex;
    align-items: center;
    justify-content: center; 
    border-radius: 50%;
    margin-right: 6% ;
    color: black !important;
}