@import "../theme";

$header-height: 80px;
$font-height: 0.9em;

div {
  background-image: none !important;
}

body {
  font-family: "Montserrat" !important;
  background-color: #f4f4f3;
}

.page {
  padding: 1.5rem;
}

.border-topright {
  border-radius: 0px 25px 0px 0px;
}

.border-topleft {
  border-radius: 25px 0px 0px 0px;
}

.leftside-expanded {
  //left menu column - flex-grow=0, flex-shrink=0, flex-basis=250px
  flex: 0 0 clamp(100px, 20%, 300px) !important;
}

.leftside-collapsed {
  //left menu column - flex-grow=0, flex-shrink=0, flex-basis=100px
  flex: 0 0 110px !important;
}

.menuLogo {
  width: 190px;
  height: 60px;
}

.menuLogoCollapsed {
  width: 40px;
  height: 40px;
}

.k-selected {
  color: #0b1dc9 !important;
  background-color: #dee5f7 !important;
}

.common-btn,
.button {
  color: $buttonTextColor;
  background-color: $primaryButtonColour;
  border: 0px solid $primaryButtonColour;
  width: auto;
  padding: 10px 20px;
  height: 40px;
  border-radius: $primaryRadius;
  min-width: 100px;
}

.common-btn:hover {
  color: $buttonHoverText;
  background-color: $buttonHoverColor;
}

.button-small {
  color: $buttonTextColor;
  background-color: $primaryButtonColour;
  border: 0px solid $primaryButtonColour;
  width: auto;
  padding: 10px 10px;
  height: 26px;
  border-radius: $primaryRadius;
  min-width: 65px;
  font-size: 11px;
}

.button-small:hover {
  color: $buttonHoverText;
  background-color: $buttonHoverColor;
}

.bgprimary {
  background-color: $primaryColour;
}

.bgsecondary {
  background-color: $secondaryColour;
}

.w-80 {
  width: 80% !important;
}

.w-70 {
  width: 70% !important;
}

.bgthird {
  background-color: $thirdColour;
}

.bgBtnSecondary {
  background-color: $secondaryButtonColour;
  color: $secondaryButtonTextColour;
}

.thirdButton {
  border-radius: 8px;
  border: 1px solid $thirdBorderColor;
  color: $thirdButtonColorHover;
  background: $thirdButtonBg;
  height: 35px;
  font-size: 0.75rem;
}

.thirdButton:hover {
  background: $thirdButtonBg;
  color: #000000;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.bgBtnSecondary:hover {
  color: $secondaryButtonHoverTextColour;
  background-color: $secondaryButtonColourHover;
}

.mattersBtn {
  background: #262626;
  color: #dcdaff;
  border: 1px solid #262626;
}

.mattersBtn:hover {
  background: white;
  color: #262626;
  border: 1px solid #7d7ab4;
}

.bgForm {
  background-color: $formFieldBackground;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);

  .k-label {
    margin-bottom: 0.2rem;
  }

  .datepicker {
    min-width: 156px !important;
    height: 40px !important;
    border-radius: 10px;
  }
  .k-input {
    // width: 100% !important;
    // min-width: 100% !important;
  }
}

.button1 {
  width: 100px;
}

.k-window-content:last-child {
  padding-bottom: 0;
}

#action_dialog,
.k-window-content,
.k-prompt-container {
  //   background: white;
  padding: 0;
}

.h-sm {
  height: 35px;
}

.h-md {
  height: 40px;
}

.docviewer {
  .k-input {
    height: 33px;
    max-width: 326px;
    border-radius: $primaryRadius;
    background: #ffffff;
    border: 1px solid #e6e6e6;
  }

  .k-i-download {
    height: auto;
  }

  .k-pdf-viewer-pages {
    background: white;
  }
}

.icon-styles {
  width: 18px;
  height: 18px;
  // filter: $iconsFilter;
}

.app-bar-title {
  font-style: normal;
  line-height: 37px;
  /* identical to box height */

  color: $mainheading_textColor;
  padding-left: 10px;
}

// SCROLL BAR

.bankRec {
  .k-selected {
    border-radius: 0px;
    outline: none;
    opacity: 1 !important;
    background-color: white !important;
  }
}

.infoBox {
  width: 170px;
  height: 24px;
  background: $secondaryColour;
  border-radius: 10px;
  font-size: 13px;
  line-height: 14px;
  color: #1f1801;

  #title {
    color: $accentColor;
  }
}

.k-animation-container.k-animation-container-relative.k-animation-container-shown {
  min-width: 200px;
}

.pdfwindow {
  .k-dialog-title {
    font-size: 1rem;
  }
  .k-window-titlebar-actions {
    display: none;
  }
}

.leftside-collapse-button {
  position: absolute;
  top: 1rem;
  cursor: pointer;
  width: 60px;
  z-index: 9;
}

.top-right {
  position: absolute;
  z-index: 10000;
  right: 0;
  top: 0;
}

.expanded-button-location {
  left: 255px;
}

.collapsed-button-location {
  left: 58px;
}

.k-table-thead,
.k-table-header,
.k-table-group-sticky-header {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #f8f8f8 !important;
}

.add-dialog {
  .k-animation-container.k-animation-container-relative {
    width: 100% !important;
    display: block;
  }
}

.spinner {
  color: $accentColor;
}

.k-dialog-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  padding-left: 20px;
}

.k-loader {
  color: $primaryColour;
}

/* Scroll bar */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  //box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $scrollbarColor;
  border-radius: $primaryRadius;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $scrollbarHoverColor;
}

.settings-dialog {
  .k-window.k-dialog {
    padding-bottom: 20px;
  }
}

.userPic {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.k-dialog-titlebar {
  background: $headingColour;
  height: 55px;
  border-radius: $secondaryRadius $secondaryRadius 0px 0px;
  color: $primaryTextColor;
}

.dialog-footer {
  height: 65px;
  background: $headingColour;
  border-radius: 0px 0px $secondaryRadius $secondaryRadius;
  display: flex;
  justify-content: end;
  align-items: center;
  //position: inherit;
}

.w-50 {
  width: 50% !important;
}

.k-dialog-wrapper .k-dialog {
  background: #ffffff;
  border-radius: 25px;
}

.batch-posting-dialog {
  .k-window-content.k-dialog-content {
    overflow: hidden !important;
  }
}

.k-icon.k-i-x.k-button-icon {
  color: #ff0d0d;
}

.k-tilelayout-item-header.k-card-header {
  display: none;
}

.k-tilelayout-item-body.k-card-body {
  @media (max-width: 1650px) {
    overflow: auto;
    padding: 20px;
    padding-bottom: 0px;
  }
}

.card-container {
  background-color: #ffffff;
  padding: 40px 0;
  text-align: center;

  .card-buttons {
    .k-button {
      min-width: 105px;
    }
  }

  .card-component {
    grid-column: 1 / -1;
    overflow: hidden;
  }
}

.wa {
  //   -webkit-appearance: none !important;
  //   -moz-appearance: none !important;
  background-color: transparent !important;
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='8' viewBox='0 0 15 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.5' d='M14 1L7.5 7L1 0.999999' stroke='%232D2D2D' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat !important;
  background-position-x: 100% !important;
  background-position-y: 50% !important;
  border: none !important;
  border-radius: 2px !important;
  margin-right: 2rem !important;
  margin-left: 0.5rem !important;
  // padding: 1px;
  padding-right: 2rem;
}

.columnArrowStyling {
  position: absolute;
  margin-top: 2px;
  margin-left: -12px;
}

.Toastify__toast-container {
  min-width: 400px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.Toastify__toast-body {
  min-width: 350px;
}

.pdf-toolbar-hidden {
  .k-toolbar {
    display: none !important;
  }
}

.k-treeview-bot {
  margin-bottom: 2px solid #bababa !important;
}

.k-treeview-leaf.k-focus {
  box-shadow: none;
}

.case-files {
  margin-top: 60px;
}

.workflow-splitter {
  box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.08);
  border-radius: 20px;

  .k-splitbar {
    margin-left: 8px;
    margin-right: 4px;
    width: 8px;
    border-radius: 10px;
  }

  .k-splitbar:hover {
    width: 12px;
    margin-right: 0px;
  }

  .k-splitbar.k-focus {
    background-color: #f0f0f0;
    color: black;
  }
}

.workflow-treeview {
  .k-treeview-leaf {
    width: 100%;

    .k-treeview-leaf-text {
      width: 100%;
    }
  }
}

.dialog-md {
  @media (min-width: 1200px) {
    .k-dialog {
      width: 925px;
    }
  }

  @media (max-width: 1200px) {
    .k-dialog {
      width: 85vw;
    }
  }

  @media (min-height: 725px) {
    .k-dialog {
      height: 675px;
    }
  }

  @media (max-height: 725px) {
    .k-dialog {
      height: 85vh;
    }
  }
}

.dialog-xmd {
  @media (min-width: 1200px) {
    .k-dialog {
      width: 940px;
    }
  }

  @media (max-width: 1200px) {
    .k-dialog {
      width: 85vw;
    }
  }

  @media (min-height: 770px) {
    .k-dialog {
      height: 770px;
    }
  }

  @media (max-height: 770px) {
    .k-dialog {
      height: 85vh;
    }
  }
}

.dialog-lg {
  @media (min-width: 1200px) {
    .k-dialog {
      width: 1000px;
    }
  }

  @media (max-width: 1200px) {
    .k-dialog {
      width: 95vw;
    }
  }

  @media (min-height: 865px) {
    .k-dialog {
      height: 865px;
    }
  }

  @media (max-height: 865px) {
    .k-dialog {
      height: 85vh;
    }
  }
}

.dialog-xxl {
  @media (min-width: 1300px) {
    .k-dialog {
      width: 1250px;
    }
  }

  @media (max-width: 1300px) {
    .k-dialog {
      width: 95vw;
    }
  }

  @media (min-height: 815px) {
    .k-dialog {
      height: 815px;
    }
  }

  @media (max-height: 815px) {
    .k-dialog {
      height: 95vh;
    }
  }
}

.dialog-fullscreen {
  .k-dialog {
    width: 100%;
    height: 100%;
  }
}

.k-dialog-actions {
  margin: 0 0 !important;
  padding: 0;
}

.error {
  .k-radio + .k-radio-label:before {
    border: 1px solid red !important;
  }
}

.customStepper {
  .k-progressbar {
    display: none;
  }
}

.customStepper .k-step-list-horizontal .k-step-link {
  max-width: 100%;
  white-space: normal;
}

.k-step-list-horizontal .k-step {
  margin: auto;
}

.k-expander-title {
  color: #000000;
  text-transform: none;
  font-weight: 600;
}

.scroll-container::-webkit-scrollbar {
  height: 10px !important; /* Ensures scrollbar always appears */
  background: #ccc; /* Light background */
}

.scroll-container::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.7) !important; /* Darker scrollbar for visibility */
  border-radius: 5px;
}

.scroll-container {
  scrollbar-width: auto !important; /* Ensures scrollbar visibility in Firefox */
}