@import "../theme";

.k-breadcrumb-link {
  color: #707070 !important;
}

.grid-smallfont {
  td {
    font-size: clamp(0.6rem, 0.5vw + 0.25rem, 0.5vw + 0.3rem);
  }
}

.workflow .k-grid {
  border: none;

  .k-table-thead {
    display: none;
  }

  .k-grid-header {
    border: none;
  }
}

.k-grid {
  border-radius: 10px 10px 10px 10px;

  .k-pager {
    display: flex;
    min-height: 50px;
    align-items: center;
  }

  .k-grid-header thead span span.k-link {
    padding: 15px 15px 15px 8px;
    color: black;
  }

  .k-table-row.k-alt {
    background: $altColour;
  }

  .k-table-row {
    border-bottom: 0.1px solid #bdbdbd !important;
    height: 40px;
  }

  td {
    font-style: normal;
    line-height: 1.5em;
    // font-size: 14px;
    color: $secondaryTextColor;
  }

  .k-grid-header-wrap,
  .k-grid-footer-wrap {
    border: none;
  }

  .k-grid-header-wrap {
    border-radius: 10px 10px 0 0;
  }

  .k-grid-footer-wrap {
    border-radius: 0px 0px 10px 10px;
  }

  .k-table-thead {
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    color: #000000;
  }

  .k-pager-sizes {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: $secondaryTextColor;
  }

  .k-pager-nav {
    width: 30px;
    height: 30px;
    background: #ffffff !important;
    border: 0.3px solid #bdbdbd !important;
    border-radius: 5px;
    margin-left: 8px;
    margin-right: 8px;
  }

  .k-pager-sizes .k-input-inner,
  .k-pager-sizes .k-input-value-text {
    text-overflow: clip;
    font-weight: 500;
    color: $primaryTextColor;
  }

  .k-pager-sizes .k-dropdown-list,
  .k-pager-sizes .k-dropdown,
  .k-pager-sizes .k-dropdownlist,
  .k-pager-sizes > select {
    margin-right: 1ex;
    width: 5.2em;
  }

  .k-button-flat-primary {
    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $secondaryTextColor;
    }
  }

  .k-selected {
    width: 28px;
    height: 27px;
    border-radius: 5px;
    outline: $accentColor;
    outline-style: solid;
    opacity: 1 !important;
    background-color: white !important;
  }

  .k-button.k-selected::before {
    background: #f8f8f8 !important;
  }

  .pager-container {
    height: 60px;
    background: #f8f8f8;
    border: 0.3px solid #bdbdbd;
    border-radius: 0px 0px 10px 10px;

    .row {
      display: flex;

      .page-container {
        display: flex;

        .btn {
          width: 28px;
          height: 28px;
          border: 1px solid red;
        }
      }
    }
  }

  .k-grid-pager {
    border-radius: 0px 0px 9px 9px !important;
    height: 60px;
    background-color: $headingColour;
  }

  .k-header {
    border: none;
  }

  .k-pager-info {
    display: inline;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: $secondaryTextColor;
    float: left;
  }
}

.gridTextNoWrap.k-grid td,
.gridTextNoWrap.k-grid .k-table-td {
  white-space: nowrap !important;
  text-overflow: clip !important;
}

.grid-row-select {
  .k-table-row.k-selected {
    outline-style: none;
    height: 40px;
  }

  tr.k-selected > td {
    background-color: rgba(18, 116, 172, 0.25);
  }

  .k-master-row > td:focus {
    //box-shadow: none;
  }
}

.settings-grid {
  .k-grid {
    background: #ffffff;
    box-shadow: 0px 40px 19px #f9f9f9;
    border-radius: 10px;
    padding: 20px;
    padding-top: 0px;
    max-height: 70vh;
    overflow: scroll;

    .k-grid-header {
      background-color: white;
    }

    .k-grid-header thead span span.k-link {
      padding: 15px;
      color: black;
    }

    .k-table-row.k-alt {
      background: white;
    }

    .k-table-row {
      border-bottom: 0.5px solid lightgrey;
      width: 80% !important;
    }

    td {
      font-style: normal;
      font-weight: 500;
      line-height: 1.5em;
      color: #1f1801;
      opacity: 1 !important;
    }

    .k-grid-header-wrap,
    .k-grid-footer-wrap {
      border: none !important;
    }

    .k-header {
      background-color: white !important;
      border-radius: none !important;
    }

    .k-table-thead {
      border: none;
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 15px;
      color: #000000;
      opacity: 0.5;
    }
  }
}

.client-services-grid {
  .k-grid {
    .k-grid-header {
      display: none;
    }
  }
}

.risk-assessment {
  .k-grid {
    padding-top: 0px;

    .k-table-row.k-alt {
      background: $altColour;
    }

    .k-table-row {
      height: 60px;
    }
  }
}

.dashboard-grid {
  .k-grid {
    background: #ffffff;
    box-shadow: none !important;
    border: none;
    border-radius: 10px;
    padding: 0px 3px 5px 20px;
    height: 100%;
    overflow: scroll;

    .k-table-thead {
      color: #707070;
      font-family: Montserrat;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    td {
      color: #707070;
      font-weight: 500;
    }
  }
}

.vat-return-grid {
  .k-grid {
    box-shadow: 0px 0px 8px #e8e8e8;

    .k-grid-header {
      display: none;
    }

    .k-table-row {
      height: 70px;
    }

    .k-table-row:hover {
      background-color: white;
    }
  }
}

.billing-grid {
  td {
    font-style: normal;
    font-size: 12px;
    line-height: 1.5em;
    opacity: 1 !important;
  }

  b {
    font-size: 12px;
  }
}

.users-grid {
  .k-grid {
    padding-bottom: 0px;
  }
}

.gridBoldField {
  //Using !important because we are overriding grid td css settings.
  font-weight: 600 !important;
  color: $primaryTextColor !important;
}

.balance-table {
  border-collapse: collapse;
  width: 100%;
  border-radius: 10px !important;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border-collapse: separate;
  border-spacing: 0;
  font-weight: 600;
  font-size: 12px;
  padding: 20px 20px 1px 20px;

  td {
    width: 50px;
  }
}

.balance-table thead {
  th {
    background: white !important;
    border-bottom: 0.5px solid rgba(171, 167, 167, 1);
  }

  height: 20px;
  border: 0.3px solid #bdbdbd;
  border-radius: 10px 10px 0px 0px !important;
  line-height: 10px;
  color: #1f1801 !important;

  tr {
    background: white !important;

    th {
      padding-top: 2px;
      padding-bottom: 10px;
      opacity: 0.5;
    }
  }
}

.balance-table tbody {
  tr {
    td {
      padding-top: 5px;
      padding-bottom: 5px;
      font-weight: 500;
      border-bottom: 0.5px solid rgba(171, 167, 167, 0.5);
    }
  }

  tr:last-child {
    td {
      border-bottom: none !important;
    }
  }
}

.balance-table body tr td:last-child {
  border-bottom: none !important;
}

.matter-balance-table {
  border-radius: none !important;
  border: none !important;
  background: #ffffff;
  box-shadow: none !important;
}

.matter-balance-table thead {
  height: 40px;

  tr {
    th {
      color: #262626;
      font-weight: 600;
      font-size: 11px;
      opacity: 1;
    }
  }
}

.matter-balance-table tbody {
  tr {
    height: 50px !important;
    color: #707070;

    td {
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }
}

.accounts-table .k-grid-header thead tr th {
  background-color: #fafafa;
  color: #424242;
  border-width: 0 0 1px 1px !important;
  border-color: rgba(0, 0, 0, 0.08) !important;
  border-style: solid !important;
}

.accounts-table .k-grid-header thead tr:nth-child(1) th span span.k-link {
  justify-content: left;
}

.accounts-table .k-grid-container .k-grid-content {
  overflow-y: hidden;
}

.accounts-table input {
  width: 25px;
  height: 25px;
}

.accounts-table input[type="checkbox"] {
  width: 15px;
  height: 15px;
}

.accounts-table .k-grid-header thead tr th.balanceCol span span.k-link {
  text-align: center !important;
  justify-content: center !important;
}

.accounts-table tbody tr td.balanceTd {
  text-align: right !important;
  justify-content: right !important;
}
.office-credit-grid.accounts-table .k-grid-container .k-grid-content {
  overflow-y: auto !important; // Using !important to override the existing accountstable style
}

.activity-table-filters {
  display: none;
}

.activity-grid {
  .k-table-row:hover {
    .activity-table-filters {
      display: flex;
    }
  }
}

//For center align grid-header-center

/* non-sortable headers */
.k-grid th.grid-header-center {
  text-align: center;
}
/* sortable headers */
th.grid-header-center .k-cell-inner > .k-link {
  justify-content: center;
}

.clientlist_gridheight {
  max-height: calc(100vh - 250px);
}

.matterlist_gridheight {
  max-height: calc(100vh - 260px);
}

.casefile_activities_gridheight {
  max-height: calc(100vh - 300px);
}

.casefile_time_gridheight {
  max-height: calc(100vh - 300px);
}

.casefile_task_gridheight {
  max-height: calc(100vh - 300px);
}

.workflow-container {
  height: calc(100vh - 300px);
}

.grid-fs13 {
  td {
    font-size: 13px;
  }
}

.grid-header-sm {
  .k-grid-header {
    height: 35px;
    align-items: center;
  }
}
