$primaryColour: #7d7ab4;
$secondaryColour: #dcdaff;
$thirdColour: #262626;
$innerTabColour: #f1f0ff;
$alternateColor: #f9f9ff;
$background-color: #f0f1f5;
$alternateColor: #fffdf8;

$accentColor: #241cca;

$primaryTextColor: #262626;
$secondaryTextColor: #707070;
$mainheading_textColor: #4843d3;

$buttonTextColor: #ffffff;
$settingsbuttonColor: #1f1801;
$settingsHoverColor: #707070;
$settingsTextColor: #ffffff;
$settingsbuttonColor: #17117b;
$settingIconColor: $accentColor;

$thirdButtonBg: #f4f4fe;
$thirdButtonColorHover: #6f6ca6;
$thirdBorderColor: #7d7ab4;

$iconsFilter: brightness(0) saturate(100%) invert(30%) sepia(58%)
  saturate(7033%) hue-rotate(239deg) brightness(72%) contrast(131%);
$buttonHoverColor: $secondaryColour;
$buttonHoverText: #1f1801;

$crossIconColor: #dcdaff;

$primaryButtonColour: $primaryColour;
$secondaryButtonColour: #dcdaff;
$secondaryButtonTextColour: #1f1801;
$secondaryButtonColourHover: #707070;
$secondaryButtonHoverTextColour: $alternateColor;

$settingOptionsColor: $primaryTextColor;

$altColour: #f9f9ff;
$headingColour: #f8f8f8;

$formFieldBackground: #fafafa;
$lineAndBorder: #e6e6e6;
$warningColour: #ff5555;

$primaryRadius: 5px;
$secondaryRadius: 20px;
$scrollbarColor: #d9d9d9;
$scrollbarHoverColor: $secondaryTextColor;

$combo-icon-color: #ffffff;

$nextlogo: "./linqlawhublogo.svg";
$collapsedLogo: "./linqlawhubIcon.svg";

:export {
  logo: $nextlogo;
  collapsedLogo: $collapsedLogo;
}
