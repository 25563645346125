@import '../../styles/theme';

.vat-container {
    // background: #FFFBEF;
    background: $secondaryColour;
    border: 1px solid #F3F3F3;    
    height: 130px;
    min-width: 250px !important;

    p {
        line-height: 8px;
    }
}

.text-line-height {
    line-height: 8px;
}

.text-light-color {
    color: #666666;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
}

.date-text {
    font-size: 1.1rem;
}

.grid-section-height {
    height: 25px;
}

.text-center {
    text-align: center;
}

.grid-btn {
    min-width: 79px !important;
}

.vat-details-container {
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    box-shadow: 0px 0px 8px #E8E8E8;
    border-radius: 10px;
    width: 80%;
    margin: auto;
    height: 80vh !important;
    margin-bottom: 10px;
    overflow: auto;
}

.vat-return-container {
    width: 80% !important;
    margin: auto;
}

.gray-text-color {
    color: #666666;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100 !important;
}

.line-bar {
    opacity: 0.1;
    border: 1px solid #000000;
}

.box-button {
    background: #D9D9D9;
    border: 1px solid #DEDEDE;
    border-radius: 6px;
    height: 30px;
    text-align: center;
    min-width: 60px;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 15px;
}

.calculation-text-color {
    color: #666666;
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 17px;
}