.section-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.section-title {
  font-weight: 700;
  font-size: 14px;
  line-height: normal;
}

.matter-item {
  border-radius: 5px;
  border: 0.5px solid var(--lines-borders-colors, #e6e6e6);
  background: #f9f9ff;
}
