@import "../../styles/_theme";

.detail-key {
  font-weight: 600;
  font-size: 13px;
  line-height: normal;
  min-width: 150px;
}
.detail-value {
  font-size: 12px;
  line-height: normal;
  color: #707070;
  min-width: 120px;
  // min-width: 100%;
}

.col-2-sec {
  // background-color: rgba(204, 203, 203, 0.12);
  border-radius: 10px;
  box-shadow: 0px 0px 16px 0px #ffffff;
  background: linear-gradient(
    180deg,
    #fff 0%,
    rgba(204, 203, 203, 0.4) 100%
  ) !important;
}
.section-title {
  font-weight: 700;
  font-size: 16px;
  line-height: normal;
}

.col-2-divider {
  opacity: 0.2;
  border-bottom: 0.5px solid #000000;
  margin-top: 14px;
  margin-bottom: 9px;
}

.white-circle {
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.contact-avatar {
  color: $alternateColor;
  background-color: $primaryColour;
  div {
    margin-top: 6px;
  }
}

.container-shadow {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}

.primary-pill {
  background-color: #dcdaff;
  color: #262626 !important;
  font-size: 11px !important;
  line-height: normal;
  font-weight: 600;
}

.category-pill {
  background-color: #dafff3;
  color: #262626 !important;
  font-size: 11px !important;
  line-height: normal;
  font-weight: 600;
}
