
.period_gridheight {
     max-height: calc(100vh - 300px);    
}

.checkbox {
     height: 18px;
     width: 18px;
     margin-left: 30px;
     color: #241cca;
}