@import "../../styles/theme";

.view-tittle {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.attach-contact-text {
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 1%;
}

.active-tab {
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  margin-right: 21px;
  border-bottom: 2px solid #ffc000;
}

.active-tab:hover {
  cursor: default;
}

.tab {
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  opacity: 0.5;
  margin-right: 21px;
}

.tab:hover {
  cursor: pointer;
}

.col-1-container {
  background: #ffffff;
  box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  overflow: auto;
  // height: 80vh;
}

.section-title {
  font-weight: 700;
  font-size: 16px;
  line-height: normal;
}

.sec-border {
  margin-top: 9px;
  margin-bottom: 30px;
  opacity: 0.2;
  border-bottom: 0.5px solid #000000;
}

.marginTop {
  margin-top: -25px;
}

.grid-top {
  margin-top: -5px;
}

.col-2-sec {
  background-color: rgba(204, 203, 203, 0.12) !important;
}

.col-2-sec1 {
  background: linear-gradient(
    360deg,
    #ffffff 0%,
    rgba(204, 203, 203, 0.12) 100%
  );
}

.details-row-padding {
  padding-left: 17px;
}

.contact-list-wrapper {
  max-height: calc(100vh - 610px);
  // padding-top: 20px;
  overflow: auto;
  border-radius: 10px;
  background-color: rgba(204, 203, 203, 0.12);
}

.del-container {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: none;
  // background-color: white;
}

.circleActive {
  width: auto;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  background: $innerTabColour;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

.circle {
  width: auto;
  padding-left: 15px;
  padding-right: 15px;
  height: 40px;
  background: #f1f0ee;
  border-radius: 10px;
}

.matterform-tab-title {
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  line-height: 18px;
  opacity: 0.7;
}

.matterform-tab-active-title {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
}

.add-clients {
  p {
    line-height: 0px;
  }
}

.pdf-icon {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: transparent;
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
}

.add-activity-form-text {
  font-style: italic;
  font-weight: 500;
  font-size: 13px;
  color: #707070;
  line-height: 14.63px;
}

.activity-form-view-image {
  display: flex;
  align-items: center;
  width: 100px;
  justify-content: space-around;
}

.form-icon {
  :hover {
    cursor: pointer;
  }
}

.attach-contact-arrow-container {
  background-color: #f1f0ee;
  width: 26px;
  height: 26px;
  border-radius: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.matter-form-tab-div {
  height: calc(80vh - 369px) !important;
  // overflow: auto !important
}

.matter-form-completion-div {
  height: calc(80vh - 369px) !important;
  // overflow: auto !important
}

@media screen and (max-width: 1200px) {
  .matter-form-tab-div {
    height: calc(80vh - 333px) !important;
    // overflow: auto !important
  }

  .matter-form-completion-div {
    height: calc(80vh - 394px) !important;
    // overflow: auto !important
  }
}

@media screen and (max-width: 992px) {
  .matter-form-tab-div {
    height: calc(80vh - 540px) !important;
    overflow-x: scroll;
  }

  .matter-form-completion-div {
    height: calc(80vh - 394px) !important;
    // overflow: auto !important
  }
}

.feeearner-multi-select {
  width: 80%;
  max-width: 100%;
  height: 44px;
  overflow: auto;
}

.font-text-size {
  font-size: 13px;
}

.container-shadow {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}

.primary-pill {
  background-color: #dcdaff;
  color: $thirdColour !important;
  font-size: 14px !important;
}

.heading-text {
  color: #333;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sub-text {
  color: $secondaryTextColor;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.popup-content {
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: #ffffff;
  border-radius: 15px;
  // width: 100px;
}

.menu-popup {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.list {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  opacity: 0.8;
}

.doc-upload-text {
  margin-right: 10px;
  color: $settingsbuttonColor;
  text-align: center;
  font-family: Montserrat;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.file-upload-icon {
  border-radius: 10px;
  background: $thirdColour;
  width: 36px;
  height: 35px;
}

.legalProceeding {
  width: 100%;
  height: 44px;
  background-color: $headingColour;
  border: 1px solid $lineAndBorder;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 16px 0px #00000014;
}

.actions {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.image-block {
  box-shadow: 0px 0px 4px 0px #00000029;
  height: 28px;
  width: 28px;
}

.image-block:hover {
  box-shadow: 0px 0px 4px 0px $secondaryTextColor;
}

.popup {
  border-radius: 10px !important;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
  padding: 10px !important;
  width: 180px;
}

.button-overlay {
  z-index: 100 !important;
}

.onboarding_container {
  padding-inline: 80px;
}

@media (max-width: 1400px) {
  .onboarding_container {
    padding-inline: 50px;
  }
}

@media (max-width: 1250px) {
  .onboarding_container {
    padding-inline: 25px;
  }
}

@media (min-width: 1600px) {
  .onboarding_container {
    padding-inline: 15%;
  }
}
