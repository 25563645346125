.suppliers_gridheight {
  max-height: calc(100vh - 230px) !important;
}

.supplierledger_gridheight {
  max-height: calc(100vh - 340px) !important;
}
.dark-background {
  background: #eaeaea;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
