.proof-holder {
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  max-height: 280px;
  overflow: auto;
}

.proof-tile {
  background-color: #ffffff;
  // box-shadow: 0px -1px 5px 3px rgba(0, 0, 0, 0.1);
  // border-radius: 10px;
}

.image-pdf {
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  text-align: center;
  color: #162eff;
  font-size: 12px;
  padding: 5px;
  min-width: 45px;
}

.comapany-proof-tabs {
  background-color: #f6f6f6;
  border-radius: 10px;
  height: 45px;
}

.in-active-tab {
  font-weight: 500;
  font-size: 14px;
  min-width: 100px;
  color: #707070;
}

.active-tab {
  font-weight: 700;
  min-width: 100px;
  font-size: 14px;
  color: #262626;
}

.horizontal-bar {
  border: 1px solid #707070;
  height: 20px;
}

.director-tile {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
  height: 450px;
}

.file-upload-icon {
  border-radius: 10px;
  background: #262626;
  width: 36px;
  height: 35px;
}

.doc-upload-text {
  margin-right: 10px;
  color: #1f1801;
  text-align: center;
  font-family: Montserrat;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.gridHeight {
  height: calc(100vh - 370px);
}

.primary-pill {
  background-color: #dcdaff;
  color: white !important;
  font-size: 11px !important;
  width: 70px;
  background-color: #262626;
  line-height: normal;
  font-weight: 600;
}

.green-pill {
  background-color: #dcdaff;
  color: white !important;
  font-size: 11px !important;
  width: 70px;
  background-color: #3bc4af;
  line-height: normal;
  font-weight: 600;
}

.container {
  padding-inline: 80px;
}

@media (max-width: 1400px) {
  .container {
    padding-inline: 50px;
  }
}

@media (max-width: 1250px) {
  .container {
    padding-inline: 25px;
  }
}

@media (min-width: 1600px) {
  .container {
    padding-inline: 15%;
  }
}
