.gridheight {
  max-height: calc(100vh - 240px);
}

.section-title {
  font-weight: 700;
  font-size: 14px;
  line-height: normal;
}

.notes-text {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #707070;
}
