.customButton {
  background-color: #f8f8f8;
  color: white;
  border-radius: 6px;
  padding: 5px 5px;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #707070;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 5px;
}

.customButton:hover {
  background-color: #e8e8e8;
  border: 1px solid #241cca;
}

@media (min-width: 1050px) and (max-width: 1249px) {
  .container {
    padding-inline: 20px;
  }
}

@media (min-width: 1250px) and (max-width: 1449px) {
  .container {
    padding-inline: 50px;
  }
}

@media (min-width: 1450px) and (max-width: 1599px) {
  .container {
    padding-inline: 60px;
  }
}

@media (min-width: 1600px) and (max-width: 1799px) {
  .container {
    padding-inline: 70px;
  }
}

@media (min-width: 1800px) {
  .container {
    padding-inline: 15%;
  }
}

.inputStyle {
  max-width: 260px !important;
  min-width: 260px !important;
  width: 260px;
  height: 32px;
  max-height: 32px;
}

.error {
  border: 1px solid red !important;
}
