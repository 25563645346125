.main-container {
  display: flex;
  justify-content: center;
}

.input-styles {
  width: 326px !important;
}

.small-inputbox {
  width: 155px !important;
}

@media (max-width: 1400px) {
  .input-styles {
    width: 260px !important;
  }
  .small-inputbox {
    width: 122px !important;
  }
}

.details-textbox {
  width: 100% !important;
  max-width: 100% !important;
  height: 50px !important;
}


@media (min-width: 1690px) {
  .bill-request-grid {
    max-height: calc(100vh - 250px);
  }
}

@media (min-width: 1218px) and (max-width: 1689px) {
  .bill-request-grid {
    max-height: calc(100vh - 250px);
  }
}

@media (max-width: 1217px) {
  .bill-request-grid {
    max-height: calc(100vh - 300px);
  }
}

@media (max-width: 968px) {
  .bill-request-grid {
    max-height: calc(100vh - 370px) !important;
  }
}

@media (max-width: 802px) {
  .bill-request-grid {
    max-height: calc(100vh - 460px) !important;
  }
}