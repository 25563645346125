@import "../../styles/theme";

.balance-table-matter {
  width: 466px;
  height: 41px;
  white-space: nowrap;
  overflow: hidden;
}

.td-header {
  font-size: 12px;
  min-width: 90px;
  white-space: nowrap;
  overflow: hidden;
}

.report-header {
  width: 100%;
  height: 44px;
  background-color: $headingColour;
  border: 1px solid $lineAndBorder;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 16px 0px #00000014;
}

.sub-text {
  color: red;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

