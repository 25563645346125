.question-text {
  font-size: 13px;
  font-weight: 600;
  text-wrap: wrap;
  line-height: 15.85px;
}

.gray-text {
  color: #1f1801;
  opacity: 50%;
  line-height: 8px;
  font-size: 10px;
}

.number-text {
  font-size: 13px;
  font-weight: 600;
  line-height: 8px;
}

.box-styles {
  background-color: #f8f8f8;
  border-radius: 15px;
  height: 73px;
}
