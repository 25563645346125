.onboarding {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px;
  height: calc(100vh - 130px);
  padding-bottom: 20px;
}

.progressBarContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  align-items: center;
}

.progressBar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #f8f8f8;
  border-radius: 25px;
  height: 45px;
  border: 1px solid #dcdaff;
  margin-bottom: 10px;
}

.step {
  z-index: 1;
  cursor: pointer;
}

.completed .stepNumber {
  background-color: #241cca;
  color: white;
}

.stepContent {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  max-width: 100%;
}

.stepNumber {
  font-size: 13px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #241cca;
  color: #241cca;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.stepName {
  font-size: 13px;
  font-weight: 600;
  margin: 12px;
}

.componentContainer {
  width: 100%;
  height: calc(100% - 43px);
}

@media (max-width: 1400px) {
  .stepName {
    font-size: 10.5px;
  }
  .stepNumber {
    font-size: 11px;
  }
}

@media (max-width: 1200px) {
  .stepName {
    display: none;
  }
}
