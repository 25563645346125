
.client-list-container {
  height: calc(100vh - 210px);
  // height: 72vh;
  position: relative;
}

.client-view-list-container {
  height: calc(100vh - 210px);
  // height: 72vh;
  position: relative;
}

.clinet-dialog-top-div {
  border-radius: 0px 0px 25px 25px;
  .k-animation-container.k-animation-container-relative {
    width: 100% !important;
  }
}

.type-combobox {
  width: 113px !important;
  height: 33px !important;
  background: #f7f7f7 !important;
}

.gap2 {
  gap: 3%;
}

.dialog {
  height: 95vh;
  width: 60%;
}

.tabsHeading {
  color: #757575;
  font-weight: none;
  border: none;
  gap: 1rem;
}

.selected {
  color: black;
  font-weight: bold;
}

.tab-height {
  height: calc(96vh - 345px);
}
.tab-height-view {
  height: 67vh;
}

.input-wdiht-height {
  width: 323px;
  max-width: 400px;
  height: 44px;
}

.mattersHeight {
  height: calc(100vh - 400px);
}

.section-title {
  font-weight: 700;
  font-size: 14px;
  line-height: normal;
}

.view-tittle {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
