@import "../../../styles/theme";


.treeview {
  overflow: hidden;
}

.tree-Item:hover {
  border-bottom: 1px solid transparent;
}

.icon {
  width: 26px;
  height: 24px;
}

.breadcrumb-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tree-Item {
  border-bottom: 1px solid $lineAndBorder;
  padding-left: 5px;
  width: 100%;
  padding-bottom: 5px;
  color: $primaryTextColor;
}

.tree-Item-selected {
  background-color: $headingColour;
  border-radius: 5px;
}

.userscreen-container {
  padding-inline: 5vw;
  height: 75vh;
  overflow: hidden;
}

.editmode-header {
  height: 7vh;
  background-color: $headingColour;
  border-radius: 12px 12px 0px 0px;
}

.draggable-button {
  width: 100%;
  background-color: #f0f1fb;
  border-radius: 10px;
  height: 38px;
  display: flex;
  align-items: center; /* Vertically centers the items */
  justify-content: flex-start; /* Ensures items start from the left */
  cursor: grab;
  padding: 0;
  position: relative;
}

.picker-icon {
  height: 100%;
  margin-right: 8px;
}

.draggable-text {
  flex-grow: 1;
  text-align: center; /* Centers the text within the button */
  font-size: 14px; /* Adjust the font size as needed */
  font-weight: 600;
  padding-right: 20px; /* Optional: Creates space for centering text better */
}


.document-item {
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f8f8f8;
}
