.main-container {
  display: flex;
  justify-content: center;
}

.input-styles {
  width: 326px !important;
}

.small-inputbox {
  width: 155px !important;
}

@media (max-width: 1400px) {
  .input-styles {
    width: 260px !important;
  }
  .small-inputbox {
    width: 122px !important;
  }
}

.details-textbox {
  width: 100% !important;
  max-width: 100% !important;
  height: 50px !important;
}

@media (max-width: 1142px) {
  .chit-posting-grid {
    max-height: calc(100vh - 445px);
  }
}

@media ((min-width: 1143px) and (max-width: 1689px)) {
  .chit-posting-grid {
    max-height: calc(100vh - 370px);
  }
}

@media (min-width: 1690px) {
  .chit-posting-grid {
    max-height: calc(100vh - 330px);
  }
}
