.document-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #F8F8F8;
  }

  .vertical-bar {
    width: 2px;
    height: 30px;
    background-color: gray;
    display: inline-block;
}