@import "../../styles/theme";

.submenu-item {
  height: 40px;
  width: 95%;
}

.bar {
  height: 120%;
  border-left: 1px solid #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 0px 0px -4px;
}

.barfirst {
  height: 60%;
  margin: 0px 0px -20px -4px;
}

.barlast {
  height: 60%;
  margin: -20px 0px 0px -4px;
}

.dot {
  width: 7px;
  height: 7px;
  background: #1f1801;
  opacity: 0.5;
  margin: 0px 0px 0px 10px;
}

.menu-icon {
  width: 21px;
  height: 19px;
  filter: $iconsFilter;
}

.settings {
  background-color: $settingsbuttonColor;
  color: $settingsTextColor;
  border: 0px solid $primaryButtonColour;
  width: auto;
  height: 40px;
  border-radius: $primaryRadius;
  min-width: 100px;
}

.settings:hover {
  background-color: $settingsHoverColor;
}

.settings-icon {
  color: $settingIconColor;
}
