
.container {
    height: 40px;
    background-color: #fafafa;
    box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.05);
    color: rgba(22, 46, 255, 1);
    text-align: end;
    font-size: 12px;
    font-weight: 600;
}

.reloadIcon {
    transition: transform 0.2s ease-in-out;

    &:hover {
        transform: rotate(20deg);
    }
}
