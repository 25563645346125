@import "../../styles/theme";

.searchBtn-fixed {
  //color: $textColor;
  display: flex;
  width: max-content;
  align-items: center;
  justify-content: center;

  @media (max-width: 1300px) {
    top: 330px;
  }
}

.del-container {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: none;
  // background-color: white;
}

.file-upload-icon {
  border-radius: 10px;
  background: #262626;
  width: 36px;
  height: 35px;
}

.count-badge {
  position: absolute !important;
  right: 0px !important;
  top: 0px;
}

.vertical-rule {
  background-color: #1f1801;
  height: 30px;
  width: 1px;
  margin-right: 10px;
}

.doc-upload-text {
  margin-right: 10px;
  color: #1f1801;
  text-align: center;
  font-family: Montserrat;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ledgerCard {
  background: #ffffff;
  border-radius: 10px !important;
  border: 0.3px solid #bdbdbd;
  width: 30vw !important;
  min-width: none;
  padding: 10px;
  border-collapse: separate;
  border-spacing: 0;

  th {
    padding-left: 10px;
    padding-bottom: 10px;
  }

  td {
    border-bottom: 0.3px solid #bdbdbd;
    padding: 5px;
  }
}

.postingDialog {
  padding: 5px;
  width: 450px;
  min-height: 240px;

  button {
    border: none;
  }
}

.wrap {
  @media (max-width: 1420px) {
    flex-wrap: wrap;
  }
}

.docs-btn {
  float: right;

  @media (max-width: 1100px) {
    float: none;
    margin-left: 80%;
  }
}

.modal-text {
  text-align: center;

  p {
    line-height: 10px;
    font-weight: 300 !important;
  }
}

.doc-button-container {
  border-radius: 0px 0px 10px 10px;
  background: #f8f8f8;
  min-height: 60px;
}

.document-header {
  background: $headingColour;
  height: 55px;
  border-radius: 10px 10px 0px 0px;
  color: $primaryTextColor;
}

.input-style {
  width: 260px !important;
  height: 32px !important;
}

.input-style span {
  height: 32px !important;
}

@media (max-width: 1050px) {
  .input-style {
    width: 240px !important;
  }
}
